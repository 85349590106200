<template>
    <div>
        <div class="table_common">
            <div class="table_box table_no_title">
                <div class="table_box_bottom">
                    <el-table :data="noticeTable" stripe border style="width: 100%">
                        <template slot="empty">
                        <!-- <NoData /> -->
                        </template>
                        <el-table-column label="序号" type="index" :index="indexMethod" align="center" header-align="center" width="92"/>
                        <el-table-column label="通知时间" prop="noticeDate" align="center" />
                        <el-table-column label="通知内容" prop="noticeContent" align="center" show-overflow-tooltip :width="noticeWidth"/>
                        <el-table-column label="通知状态" prop="noticeState" align="center" border :width="stateWidth">
                            <template slot-scope="scope">
                                <p v-if="scope.row.noticeState=='1'">未读</p>
                                <p v-else>已读</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="操 作" align="center" fixed="right">
                            <template slot-scope="scope">
                                <div class="table_oper flex_center">
                                    <el-tooltip class="item_color" effect="light" content="详情" placement="top">
                                        <i class="iconfont icon-shuxingliebiaoxiangqing" @click="noticeDetail(scope.row)"></i>
                                    </el-tooltip>
                                    <el-tooltip class="item_delete" effect="light" content="删除" placement="top">
                                        <i class="iconfont icon-shanchu" @click="delNotice(scope.row, scope.$index)"></i>
                                    </el-tooltip>
                                </div>
                                </template>
                        </el-table-column>
                    </el-table>
                    <Page :totalFont="true" :total="noticeObj.total" :currentPage='currentPage' @onPageChange="onPageChange"></Page>
                </div>
            </div>
        </div>

        <!-- 通知详情弹框 -->
        <el-dialog title="通知详情" :visible.sync="noticeDetailDialog" center class="dialog_width500" :close-on-click-modal="false">
            <div class="inner_common">
                <div class="notice_cont">{{detailForm.noticeContent}}</div>
                <div>
                    <!-- 核心企业 -->
                    <template v-if="this.userInfo.roleId==3">
                        <!-- 我的发票 -->
                        <router-link class="link_other" to="/system/coreEnterprise/applyFinancing" v-if="detailForm.transfer==1">跳转至相关页面</router-link>
                        <!-- 我的融资 -->
                        <router-link class="link_other" to="/system/coreEnterprise/myFinancing" v-else-if="detailForm.transfer==2">跳转至相关页面</router-link>
                        <!-- 核心企业 -->
                        <router-link class="link_other" to="/system/coreEnterprise/mySupplier" v-else-if="detailForm.transfer==9">跳转至相关页面</router-link>
                        <!-- 不跳转 -->
                        <template v-else></template>
                    </template>
                    <!-- 资方 -->
                    <!-- 供应商 -->
                    <template v-else>
                        <!-- 我的发票 -->
                        <router-link class="link_other" to="/system/supplier/applyFinancing" v-if="detailForm.transfer==1">跳转至相关页面</router-link>
                        <!-- 我的融资 -->
                        <router-link class="link_other" to="/system/supplier/myFinancing" v-else-if="detailForm.transfer==2">跳转至相关页面</router-link>
                        <!-- 我的公海 -->
                        <router-link class="link_other" to="/system/investor/publicResource" v-else-if="detailForm.transfer==3">跳转至相关页面</router-link>
                        <!-- 我的私海 -->
                        <router-link class="link_other" to="/system/investor/privateResources" v-else-if="detailForm.transfer==4">跳转至相关页面</router-link>
                        <!-- 核心企业 -->
                        <router-link class="link_other" to="/system/coreEnterprise/mySupplier" v-else-if="detailForm.transfer==9">跳转至相关页面</router-link>
                        <!-- 不跳转 -->
                        <template v-else></template>
                    </template>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="footer_button1" type="primary" @click="noticeDetailDialog=false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {findUserNotiList,delUserNotiInfoById,findUserNotiInfoById} from "@/api/centerPerson.js";
// import NoData from '@/components/noData'
import Page from '@/components/page'
export default {
    components: {Page},
    data(){
        return{
            noticeDetailDialog:false,
            noticeObj:{
                id:'',
                pageNo:1,
                pageSize:8,
                total:0
            },
            noticeTable:[],
            detailForm:{},
            currentPage:1,//当前页
            fetchNum:8,//当前分页数
            userInfo: JSON.parse(localStorage.getItem("userInfo")) || "",
        }
    },
    created(){
        this.resizeFn();
        this.findUserNotiList() 
    },
    methods:{
        noticeDetail(row){
            this.noticeDetailDialog=true
            let id=row.id  
            this.findUserNotiInfoById(id,row)
        },
        //获取通知详情
        async findUserNotiInfoById(id,row){
            let res=await findUserNotiInfoById("Id="+id)
            if(res && res.code==200){
              row.noticeState=0
              this.detailForm=res.data
            }else{
                this.$message.error(res.msg)
            }
        },
        //删除一条通知
        async delNotice(row,index){
            let res=await delUserNotiInfoById("Id="+row.id)
            if(res && res.code==200){
                this.noticeObj.pageNo=1
                this.currentPage=1
                this.noticeObj.pageSize=8
                this.findUserNotiList() 
                this.$message.success(res.msg);
            }
            else{
                this.$message.error('删除失败！');
            }
        },
        //获取通知列表
        async findUserNotiList(){
            let res=await findUserNotiList("pageNo="+this.noticeObj.pageNo+"&pageSize="+this.noticeObj.pageSize)
            if(res && res.code==200){
              this.noticeObj=res.data
              this.noticeTable=res.data.records
            }
        },
        //分页方法
        onPageChange (event) {
            this.noticeObj.pageNo = event
            this.currentPage=event
            this.noticeObj.pageSize=8
            this.findUserNotiList()
        },
        //序号Index 翻页递增  currentPage 当前页,  当前页显示条数
        indexMethod (index) {
            return (this.currentPage-1)*this.fetchNum +index+1;
        },
        resizeFn() {
            if (document.body.clientWidth > 1664) {
                this.noticeWidth = 700
                this.stateWidth=110
            } else {
                this.noticeWidth = 465
                this.stateWidth=80
            }
        },
    }
    
}
</script>
<style scoped>
.notice_cont{
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 30px;
    font-size: 14px;
}
.link_other{
    display: block;
    text-align: right;
    font-size: 14px;
    color: #659DFC;
    line-height: 19px;
    text-decoration: underline;
    margin-bottom: 15px;
}
@media screen and (max-width : 1664px) {
    .notice_cont{
        margin-top: 7px;
        margin-bottom: 20px;
        line-height: 20px;
        font-size: 12px;
    }
    .link_other{
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 10px;
    }
}
</style>

